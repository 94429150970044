<template>
    <div class="pay_buy_more" id="agentTitle">
        <header>
            <div class="img_box_"><img src="https://img.nnabc.cn/WechatIMG2542.jpeg" alt=""></div>
        </header>
        <section>
            <div class="box_">
                <p class="p_title__">支付获取您的姓氏 "{{ orderinfo.xname }}" 的：</p>
                <h1 class="h1_title__">好的名字提升你一生运势</h1>
                <p class="p1_text__">50套增运聚财好名供君选择</p>
                <p class="p1_text__">可用于网名、别名、职场花名、艺名等</p>
                <div class="number_box__">
                    <span class="span_left___">
                        <span class="top____">原价: ￥{{ price.fake_amount }}</span>
                        <span class="bottom____">￥{{ price.true_amount }}</span>
                    </span>
                    <span class="span_right___">
                        <span class="top____">限时优惠结束</span>
                        <span class="bottom____">
                            <van-count-down  @change="timeTrigger" millisecond :time="time">
                                <template #default="timeData">
                                    <span class="block_">0{{ timeData.hours }}</span>
                                    <span class="colon">:</span>
                                    <span class="block_">0{{ timeData.minutes }}</span>
                                    <span class="colon">:</span>
                                    <span class="block_">{{ timeData.seconds &lt; 10 ? '0'+timeData.seconds : timeData.seconds }}</span>
                                    <span class="colon">:</span>
                                    <span class="block_">{{ parseInt(timeData.milliseconds / 10) }}</span>
                                </template>	
                            </van-count-down>
                        </span>
                    </span>
                </div>
                <button class="button_we_chat___" @click="getpayurl('wechat','v1_pay_wechat')"><img src="https://img.nnabc.cn/25161650364496_.pic_hd.jpg" alt=""></button>
                <p class="p2_text___">已有<span class="color____">9, 316, 618</span>个人支付,获得了美名</p>
                <div class="img_box__"><img src="https://img.nnabc.cn/WechatIMG2543.png" alt=""></div>
                <p class="p3_text__">支付成功后回到此页面查看起名结果</p>
                <p class="p4_text__"><span class="color___">√ </span> 200套吉祥美名+名字寓意</p>
                <p class="p4_text__"><span class="color___">√ </span> 五行分析、喜忌分析</p>
                <p class="p4_text__"><span class="color___">√ </span> 音形义分析，确保所有名字好听、好意、好记！</p>
                <p class="p4_text__">再 <span class="color___">赠送</span> 您性格特点、先天潜力分析</p>
            </div>
            <div class="img_box_">
                <img src="https://img.nnabc.cn/WechatIMG2546.jpeg" alt="">
            </div>
            <div class="img_box_">
                <img src="https://img.nnabc.cn/WechatIMG2545.jpeg" alt="">
            </div>
            <div class="img_box_">
                <img src="https://img.nnabc.cn/WechatIMG2544.jpeg" alt="">
            </div>
            <div class="img_box_">
                <img src="https://img.nnabc.cn/WechatIMG2547.jpeg" alt="">
            </div>
        </section>
        <footer>
            <button class="button_box_ animation_scaling" v-if="fixedbtn" @click="getpayurl('wechat','v1_pay_wechat')">立即获取美名</button>
        </footer>
    </div>
</template>

<script>
import Comments from "../components/Comment.vue";
import vueSeamlessScroll from "vue-seamless-scroll";
import orderscroll from "../components/OrderScroll.vue";
import axios from "../utils/request";
import solarLunar from "../components/datepick/solarlunar";
import { CountDown } from "vant";

import { Overlay } from "vant";
import { Toast } from "vant";

import { Dialog } from "vant";

import router from "../router";

import report from "../utils/stat";
export default {
    name: "Pay",
    components: {
        Comments,
        vueSeamlessScroll,
        vanCountDown: CountDown,
        vanOverlay: Overlay,
        vanToast: Toast,
        orderscroll
    },
    data( ) {
        return {
            time:localStorage.getItem("time"),//计时器
            fixedbtn:false, //是否隐藏底部付费解锁全部内容按钮
             buttom_src_img:'http://cdn1.zhiyileiju.cn/WechatIMG606.png',
            buttom_top_src_img:'http://cdn1.zhiyileiju.cn/WechatIMG602.jpeg',
            counttime:10*60*1000,
            listData: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            orderlist: [],
            activeNames: ["1"],
            showPayDialog: false,
            showfixPayBtn: true,
            shopPayConfirm: false,//支付后弹窗
            // shopPayConfirm: true,//支付后弹窗
            fixedbtn: false,
            orderinfo: {
                bDate: [2021],
                nongli: {},
                eightChara: {
                    bazi: [{ y: "辛丑" }, { m: "乙未" }, { d: "甲子" }, { h: "甲子" }],
                    nayin: [
                        { y: "壁上土" },
                        { m: "沙中金" },
                        { d: "海中金" },
                        { h: "海中金" },
                    ],
                },
                askTao: {
                    qk_score_ratio: {
                        金: "6%",
                        木: "11%",
                        水: "25%",
                        火: "12%",
                        土: "46%",
                    },
                },
            },
            price: {
                base_level: null,
                created_at: null,
                fake_amount: "198.00",
                fast_amount: null,
                id: 1,
                true_amount: "",
                type: 1,
                updated_at: null,
            },
            orderfakedata: [{
                    name: "王妈妈",
                    time: "12分钟前",
                },
                {
                    name: "李妈妈",
                    time: "7分钟前",
                },
                {
                    name: "孙爸爸",
                    time: "3分钟前",
                },
                {
                    name: "刘妈妈",
                    time: "5分钟前",
                },
                {
                    name: "赵阿姨",
                    time: "1分钟前",
                },
                {
                    name: "周妈妈",
                    time: "3分钟前",
                },
                {
                    name: "李阿姨",
                    time: "3分钟前",
                },
                {
                    name: "古妈妈",
                    time: "5分钟前",
                },
            ],
        }
    },
    
    async created(){
        this.initData();
         if ( window.localStorage.getItem('qm_red_packet_type') === null) {
            this.buttom_src_img = 'http://cdn1.zhiyileiju.cn/WechatIMG606.png'
            this.buttom_top_src_img = 'http://cdn1.zhiyileiju.cn/WechatIMG602.jpeg'
        }else{
            this.buttom_src_img = 'http://cdn1.zhiyileiju.cn/WechatIMG607.png'
            this.buttom_top_src_img = 'http://cdn1.zhiyileiju.cn/WechatIMG608.jpeg'
        }
        let paytype = this.$route.params.type;
        this.orderid = this.$route.params.id;
        this.type = this.$route.params.type;
        if (paytype != 10) {
            try {
                await this.mcheckorder();
            } catch (err) {}
            this.shopPayConfirm = true;
        } else {}
        this.getorderinfo(this.$route.params.id);
        this.getFakeOrder();
        await this.getprice();
        this.showToast();
    },
    methods:{
        initData( ) {
            window.addEventListener("scroll", this.handleScroll);
            if( localStorage.getItem("time") === null ) {
                localStorage.setItem("time","600000");
                this.time = 600000;
            }else if ( localStorage.getItem("time") == 0 ){
                localStorage.setItem("time","600000");
                this.time = 600000;
            }
        },
        handleScroll() {//y页面向下拉300时显示按钮
			var scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			if (scrollTop > 300) {
				this.fixedbtn = true;
			} else {
				this.fixedbtn = false;
			}
		},
        timeTrigger (TimeData) {//计时器变化触发(支付倒计时)
            //每次触发计时器后存到本地储存里，保证页面刷新计时器不重新开始
            if( localStorage.getItem("time") === null ) {
                this.time = 600000;
            }else if ( localStorage.getItem("time") == 0 ){
                this.time = 600000;
            }else{
                let MM = TimeData.minutes * 60
                let ss = TimeData.seconds + MM
                let mss_ = TimeData.milliseconds + (ss*1000)
                localStorage.setItem("time",mss_);
                
            }
        },
        pay(action) {
            this.showPayDialog = true;
            report(action);
        },
        unpay(){
            report("v1_pay_account_paid");
            // router.back();
            if ( window.localStorage.getItem('qm_red_packet_type') === null) {
                this.$router.replace('/index')
            }else{
                location.href = "weixin://dl/business/?t=dqnSN5t1Ehe";
            }
           
        },
        async getFakeOrder() {
            let info = await axios.post("/ekaf", {
                type: 2,
                perPage: 15,
                page: 1,
                fromk: this.$route.query.from
            });
            this.orderlist = info.data.data;
        },

        showToast() {
            let t = this;
            let arr = this.orderfakedata;
            let data = arr[Math.floor(Math.random() * arr.length)];
            Toast({
                message: `${data.time} ${data.name}支付了${this.price.true_amount}元国学起名`,
                position: "bottom",
                duration: 2000,
            });
            this.timer = setTimeout(function() {
                t.showToast();
            }, 5000);
        },

        async timeout(time) {
            return new Promise((sol, ret) => {
                setTimeout(() => {
                    sol()
                }, time);
            })
        },

        async getpayurl(payway, aciton) {
             if ( payway === '') {
                payway = this.$route.params.type === '1' ? 'alipay' : 'wechat'
             }else{
                report(aciton);
             }
            Toast.loading({
                message: '请稍后...',
                forbidClick: true,
            });
            
            let orderid = this.orderid;
            let type = payway == "alipay" ? 1 : 2;
            this.type = type;
            let orderinfo = await axios.get("/order/" + orderid);
            if (
                orderinfo.data.data.order_status == "已完成" ||
                orderinfo.data.data.order_status == "已支付"
            ) {
                Dialog.confirm({
                    message: "当前订单已支付",
                    showCancelButton: false,
                }).then(() => {
                    sessionStorage.setItem('paysuccess','1');
                    router.replace({ name: "paysuccess", params: { id: orderid, type } }).catch(() => {});
                });
                return;
            }
            let t;
            setTimeout(async function() {
                await t.checkorder(orderid, type);
            }, 2000);
            try {
                let confirm_order_data_ = {
                    order_id: orderid,
                    paytype: type,
                }
                await this.confirmOrder(confirm_order_data_);
                Toast.clear()
                location.href = `https://api.zhiyileiju.cn/api/v1/payment/${payway}/${orderid}`;
                this.shopPayConfirm = true;
            } catch (err) {}
        },
        async getprice() {
            let price = await axios.get("/getSalesPrice");
            let from = this.$route.query.from || 10;
            this.price = price.data.data.filter((item) => item.fromk == from)[0];
            // console.log('this.price',this.price)
        },
        async getorderinfo(id) {
            let data = await axios.get("/order/" + id);
            this.orderinfo = data.data.data;
            if (
                this.orderinfo.order_status == "已支付" ||
                this.orderinfo.order_status == "已完成"
            ) {
                Dialog.confirm({
                    message: "当前订单已支付",
                    showCancelButton: false,
                }).then(() => {
                    router.replace({ name: "paysuccess", params: { id, type: 1 } });
                });
            } else if (this.orderinfo.order_status == "已关闭") {
                Dialog.confirm({
                    message: "当前订单已关闭",
                    showCancelButton: false,
                }).then(() => {
                    router.replace({ name: "Index" }).catch(() => {}); // on confirm
                });
            }
            let birth = data.data.data.pub_birth_date ?
                data.data.data.pub_birth_date :
                data.data.data.pub_prebirth_date;
            let fdate = birth.split(" ");
            this.orderinfo.bDate = [...fdate[0].split("-"), ...fdate[1].split(":")];
            this.orderinfo.nongli = solarLunar.solar2lunar(
                this.orderinfo.bDate[0],
                this.orderinfo.bDate[1],
                this.orderinfo.bDate[2]
            );
            console.log(this.orderinfo.nongli)
            let nowtime = Date.now();
            let cDate = data.data.data.created_at.replace(" ", "T");
            let createtime = new Date(cDate).getTime();
            // this.time = 10 * 60 * 1000 - nowtime + createtime;
        },
        checkorder(id, type) {
            this.timer = setInterval(async () => {
                try {
                    let data = await axios.post("/payment/query", {
                        paytype: type,
                        order_id: id,
                    });
                    if (data.data.data.is_pay) {
                        sessionStorage.setItem('paysuccess','1');
                        router.replace({
                            name: "paysuccess",
                            params: { id, type: data.data.data.type },
                        }).catch(() => {});
                    }
                } catch (err) {}
            }, 5000);
        },
        async mcheckorder() {
                let data = await axios.post("/payment/query", {
                    paytype: this.type,
                    order_id: this.orderid,
                });
                if (data.data.data.is_pay) {
                    sessionStorage.setItem('paysuccess','1');
                    router.replace({
                        name: "paysuccess",
                        params: { id: this.orderid, type: data.data.data.paytype },
                    }).catch(() => {});
                } else {
                    // this.getpayurl('','')
                // Toast.fail("当前订单未支付");
                }
                   
           
           
        },
        getpayurls () { //弹框立即付款
        report("v1_pay_immediate_payment");
            this.getpayurl('','')
        },
        async Dcheckorder() {
            report("v1_pay_non");
            try {
                this.mcheckorder();
                let data = await axios.post("/payment/query", {
                    paytype: this.type,
                    order_id: this.orderid,
                });
                if (data.data.data.is_pay) {
                    sessionStorage.setItem('paysuccess','1');
                    router.replace({
                        name: "paysuccess",
                        params: { id: this.orderid, type: data.data.data.paytype },
                    }).catch(() => {});
                } else {
                    this.getpayurl('','')
                // Toast.fail("当前订单未支付");
                }

            } catch (err) {
                this.getpayurl('','')
                // Toast.fail("当前订单未支付");
            }
        },
        async confirmOrder(confirm_order_data_) {
            try {
                if ( window.localStorage.getItem('qm_red_packet_type') === null) {
                    let aa = await axios.post("/order/confirm", {
                        order_id: confirm_order_data_.order_id,
                        paytype: confirm_order_data_.paytype,
                    });
                }else{
                    let aa = await axios.post("/order/confirm", {
                        order_id: confirm_order_data_.order_id,
                        paytype: 2,
                        discount_id: 1
                    });
                }
                
            } catch (err) {}
        },
        bottombtnclick() {
            window.pageYOffset = 0;
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
        },
        handleScroll() {
            var scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            if (scrollTop > 300) {
                this.fixedbtn = true;
            } else {
                this.fixedbtn = false;
            }
        },
    },
    destroyed() {
        this.timer && clearTimeout(this.timer);
    },
}
</script>

<style lang="scss" scoped>
    .pay_buy_more{
        h1,h2,h3,h4,h5,h6,p{
            margin: 0;
            padding: 0;
        }
        img{
            width: 100%;
            height: 100%;
            display: block;
        }
        button{
            background: none;
            border: none;
        }
        header{
            .img_box_{
                width: 7.5rem;
                height: 3.32rem;
            }
        }
        section{
            margin-bottom: 1.15rem;
            .box_{
                width: 7.18rem;
                background: #FFFFFF;
                border-radius: .10rem;
                margin: auto;
                overflow: hidden;
                .p_title__{
                    width: 7.18rem;
                    height: .62rem;
                    line-height: .62rem;
                    font-size: .28rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    text-indent: .24rem;
                    color: #62615D;
                    background: rgba(242, 238, 223, .3);
                    margin-top: .33rem;
                    margin-bottom: .3rem;
                }
                .h1_title__{
                    font-size: .48rem;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #323232;
                    text-align: center;
                    margin-bottom: .24rem;
                }
                .p1_text__{
                    font-size: .24rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #323232;
                    text-align: center;
                }
                .number_box__{
                    display: flex;
                    justify-content: space-between;
                    margin-top: .47rem;
                    .span_left___{
                        display: flex;
                        flex-direction: column;
                        margin-left: .58rem;
                        .top____{
                            text-decoration: line-through;
                            font-size: .24rem;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #979797;
                        }
                        .bottom____{
                            font-size: .66rem;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #FD0101;
                            text-indent: -0.12rem;
                        }
                    }
                    .span_right___{
                        display: flex;
                        flex-direction: column;
                        width: 2.48rem;
                        height: 1.2rem;
                        margin-right: .35rem;
                        border-left: .01rem solid rgba(151, 151, 151, 1);
                        .top____{
                            font-size: .24rem;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #323232;
                            text-align: right;
                            margin-bottom: .15rem;
                        }
                        .bottom____{
                            text-align: right;
                            .colon {
                                display: inline-block;
                                margin: 0 .04rem;
                                font-size: .2rem;
                                color: #E7010E;
                            }
                            .block_ {
                                display: inline-block;
                                width: .43rem;
                                color: #fff;
                                font-size: .24rem;
                                text-align: center;
                                background-color: #E7010E;
                                box-sizing:border-box;
                                border: 1px solid #E7010E;
                            }
                        }
                    }
                }   
                .button_we_chat___{
                    display: block;
                    width:5.60rem;
                    height: .95rem;
                    margin: auto;
                    margin-top: .6rem;
                    margin-bottom: .22rem;
                    img{
                        display: block;
                    }
                }
                .p2_text___{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: .22rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #525252;
                    margin-bottom: .31rem;
                    .icon____{
                        display: inline-block;
                        width: 1.06rem;
                        height: .35rem;
                    }
                    .color____{
                        color: #E7010EFF;
                        margin-top: .04rem;
                    }
                }
                .img_box__{
                    width: 6.41rem;
                    height: .77rem;
                    margin: auto;
                    margin-bottom: .43rem;
                }
                .p3_text__{
                    font-size: .28rem;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #C94B4B;
                    margin-bottom: .32rem;
                    margin-left: .41rem;
                }
                .p4_text__{
                    font-size: .24rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: rgba(50, 50, 50, 1);
                    margin-bottom: .28rem;
                    margin-left: .41rem;
                    .color___{
                        color: rgba(201, 75, 75, 1);
                    }
                }
                .p4_text__:nth-last-child(1){
                    margin-bottom: .6rem;
                }

            }
            .img_box_{
                width: 7.18rem;
                margin: auto;
                margin-top: .13rem;
            }
        }
        footer{
            height: 1.11rem;
           .button_box_{
               position: fixed;
               bottom: 0;
               width: 7.5rem;
               height: 1.11rem;
               background: #B95353;
               font-size: .36rem;
               font-family: Microsoft YaHei;
               font-weight: bold;
               color: #FEFBFB;
           } 
        }
    }
</style>